<template>
  <div class="register">
    <div class="hero text-center">
      <img
        src="@/assets/img/ticket/banner.png"
        class="img-fluid"
        alt="hero-reg"
        v-if="type == 'desktop'"
      />
      <img
        src="@/assets/img/ticket/banner-mobile.png"
        class="img-fluid"
        alt="hero-reg"
        v-else
      />
    </div>
    <div class="reg">
      <div class="register-text text-center py-4">
        <h1 class="text-uppercase Sansrace-Regular mb-0">REGISTRATION FORM</h1>
      </div>
      <div class="reg-form py-5">
        <div class="container-xl">
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="inputState"
                >Salutation<span class="text-danger">*</span></label
              >

              <select
                id="inputState"
                class="form-control"
                v-model="participant.salutation"
              >
                <option selected>Mr.</option>
                <option>Ms.</option>
                <option>Dr.</option>
                <option>Prof.</option>
                <option>A/Prof.</option>


              </select>
              <span
                class="text-danger"
                v-for="message of validation_message.salutation"
                :key="`salutation-${message}`"
                >{{ message }}<br
              /></span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputFirstName4"
                >First Name<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="inputFirstName4"
                placeholder="Name"
                v-model="participant.first_name"
              />
              <span
                class="text-danger"
                v-for="message of validation_message.first_name"
                :key="`first_name-${message}`"
                >{{ message }}<br
              /></span>
            </div>
            <div class="form-group col-md-6">
              <label for="inputLastName4"
                >Last Name<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="inputLastName4"
                placeholder="Name"
                v-model="participant.last_name"
              />
              <span
                class="text-danger"
                v-for="message of validation_message.last_name"
                :key="`last_name-${message}`"
                >{{ message }}<br
              /></span>
            </div>
          </div>
          <div class="form-group">
            <label for="inputOrg"
              >Organisation<span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="inputOrg"
              placeholder=""
              v-model="participant.organization"
            />
            <span
              class="text-danger"
              v-for="message of validation_message.organization"
              :key="`organization-${message}`"
              >{{ message }}<br
            /></span>
          </div>
          <div class="form-group">
            <label for="inputJob"
              >Designation / Job Title<span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="inputJob"
              placeholder=""
              v-model="participant.job_title"
            />
            <span
              class="text-danger"
              v-for="message of validation_message.job_title"
              :key="`job_title-${message}`"
              >{{ message }}<br
            /></span>
          </div>
          <div class="form-group">
            <label for="inputEmail"
              >Professional Email<span class="text-danger">*</span></label
            >
            <input
              type="email"
              class="form-control"
              id="inputEmail"
              placeholder=""
              v-model="participant.email"
            />
            <span
              class="text-danger"
              v-for="message of validation_message.email"
              :key="`email-${message}`"
              >{{ message }}<br
            /></span>
          </div>
          <div class="form-group">
            <label for="profNumber">Professional Number (If Applicable)</label>
            <input
              type="text"
              class="form-control"
              id="profNumber"
              placeholder=""
              v-model="participant.phone"
            />
            <span
              class="text-danger"
              v-for="message of validation_message.phone"
              :key="`phone-${message}`"
              >{{ message }}<br
            /></span>
          </div>
          <div class="form-group">
            <label for="dietRest">Dietary Restriction (If Applicable)</label>

            <select
              id="dietRest"
              class="form-control"
              v-model="participant.dietary_restriction"
            >
              <option selected>NIL</option>
              <option value="vegetarian">Vegetarian</option>
              <option value="others">Others: Please elaborate below</option>
            </select>
            <span
              class="text-danger"
              v-for="message of validation_message.dietary_restriction"
              :key="`dietary_restriction-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div
            class="form-group"
            v-if="participant.dietary_restriction == 'others'"
          >
            <label for="dietRest">Dietary Others</label>
            <input
              type="text"
              class="form-control"
              id="profNumber"
              placeholder=""
              v-model="participant.dietary_other"
            />

            <span
              class="text-danger"
              v-for="message of validation_message.dietary_other"
              :key="`dietary_other-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group">
            <label for="dec-org"
              >Do you have a purchasing decision-making role in your
              organisation?<span class="text-danger">*</span></label
            >
            <select
              id="dec-org"
              class="form-control"
              v-model="participant.decision_making"
            >
              <option selected>Yes, I Am A Purchasing Decision Maker</option>
              <option>
                No, I Influence/Make Recommendation In Purchasing Decision
              </option>
              <option>No Or Not Applicable</option>
            </select>
            <span
              class="text-danger"
              v-for="message of validation_message.decision_making"
              :key="`decision_making-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group">
            <label for="industry-org"
              >Which industry or focus area best describes your
              organisation?<span class="text-danger">*</span></label
            >
            <select
              id="industry-org"
              class="form-control"
              v-model="participant.industry"
            >
              <option selected>Healthcare Providers</option>
              <option>
                Community / Intermediate And Long Term Care Providers (Nursing
                Homes, Day Care Centres etc.)
              </option>
              <option>Pharma/Biotech</option>
              <option>Medical Device/Technology</option>
              <option>Digital Health</option>
              <option>Social Service Agencies</option>
              <option>Operational Technology Provider</option>
              <option>
                Academic, Institutes of Higher Learning (IHLS) or Research
                Institutions (RI)
              </option>
              <option>Learning & Professional Development</option>
              <option>
                Regulator/ Policy-Maker/ Government Agencies (MOH, MSF, AIC
                etc.)
              </option>
              <option value="others">Others:</option>
            </select>
            <span
              class="text-danger"
              v-for="message of validation_message.industry"
              :key="`industry-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group" v-if="participant.industry == 'others'">
            <label for="dietRest">Industry Others</label>
            <input
              type="text"
              class="form-control"
              id="profNumber"
              placeholder=""
              v-model="participant.industry_other"
            />

            <span
              class="text-danger"
              v-for="message of validation_message.industry_other"
              :key="`industry_other-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group">
            <label for="business-chall"
              >Which of the following best describes your business needs or
              challenges?<span class="text-danger">*</span></label
            >
            <select
              id="business-chall"
              class="form-control"
              v-model="participant.business_need"
            >
              <option selected>Funding/Investment</option>
              <option>Business Development/Partnerships</option>
              <option>Regulatory/Compliance</option>
              <option>Talent Acquisition</option>
              <option>Operational Challenge</option>
              <option value="others">Others:</option>
            </select>
            <span
              class="text-danger"
              v-for="message of validation_message.business_need"
              :key="`business_need-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group" v-if="participant.business_need == 'others'">
            <label for="dietRest">Others (Please elaborate below)</label>
            <input
              type="text"
              class="form-control"
              id="profNumber"
              placeholder=""
              v-model="participant.business_other"
            />

            <span
              class="text-danger"
              v-for="message of validation_message.business_other"
              :key="`business_other-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group">
            <label for="org-meet"
              >Which type(s) of organisations would you like to meet with?<span
                class="text-danger"
                >*</span
              ></label
            >
            <select
              id="org-meet"
              class="form-control"
              v-model="participant.organization_type"
            >
              <option selected>Startups</option>
              <option>Healthcare Providers</option>
              <option>Community Care Providers</option>
              <option>Social Service Agencies</option>
              <option>Established Enterprises</option>
              <option>Research And Academia</option>
            </select>
            <span
              class="text-danger"
              v-for="message of validation_message.organization_type"
              :key="`organization_type-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group">
            <label for="partnership"
              >What type(s) of partnerships or collaborations are you interested
              in exploring?</label
            >
            <select
              id="partnership"
              class="form-control"
              v-model="participant.partnership"
            >
              <option selected>Research & Development</option>
              <option>Licensing</option>
              <option>
                Technology Transfer (Eg. Adoption Of Solutions To Augment Care)
              </option>
              <option>Commercialisation</option>
              <option>Distribution/Marketing</option>
              <option value="others">Others</option>
            </select>
            <span
              class="text-danger"
              v-for="message of validation_message.partnership"
              :key="`partnership-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group" v-if="participant.partnership == 'others'">
            <label for="dietRest">Others( Please elaborate below)</label>
            <input
              type="text"
              class="form-control"
              id="profNumber"
              placeholder=""
              v-model="participant.patnership_other"
            />

            <span
              class="text-danger"
              v-for="message of validation_message.patnership_other"
              :key="`patnership_other-${message}`"
              >{{ message }}<br
            /></span>
          </div>

          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
                v-model="participant.condition_1"
              />
              <span
                class="text-danger"
                v-for="message of validation_message.condition_1"
                :key="`condition_1-${message}`"
                >{{ message }}<br
              /></span>
              <label class="form-check-label" for="gridCheck">
                <p>
                  By submitting this form, I consent to the collection, use
                  and/or disclosure of my personal data for CHI Events and
                  updates
                </p>
                <p>
                  Please kindly note that your information will be used and
                  disclosed for the purposes of CHI events and updates and will
                  be protected as required under PDPA.
                </p>
                <p class="mb-0">
                  You may refer to our NHG Personal Data Protection Policy for
                  further information.
                </p>
                <p>
                  <a
                    href="https://www.ttsh.com.sg/Pages/NHG-Personal-Data-Protection-Policy.aspx"
                    target="_blank"
                    >https://www.ttsh.com.sg/Pages/NHG-Personal-Data-Protection-Policy.aspx</a
                  >
                </p>
              </label>
            </div>
          </div>
          <div class="row my-3" v-if="this.success_alert">
            <div class="col-sm-8 mx-auto">
              <div class="alert alert-success" role="alert">
                Your registration has been submitted successfully. The organisers will be in touch with you soon. Thank you.
              </div>
            </div>
          </div>
          <div class="text-center py-4">
            <button
              type="submit"
              v-if="!loading"
              class="btn rounded-pill register-btn text-decoration-none"
              @click="onSubmit"
            >
              SUBMIT
            </button>
          </div>

          <div class="text-center py-4" v-if="loading">
            <button
              type="submit"
              class="btn rounded-pill register-btn text-decoration-none"
              @click="onSubmit"
            >
              Please Wait
            </button>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";
export default {
  name: "register",
  components: {
    Footer,
  },
  data() {
    return {
      type: "desktop",
      loading: false,
      success_alert: false,
      participant: {
        salutation: "",
        first_name: "",
        last_name: "",
        organization: "",
        job_title: "",
        email: "",
        phone: "",
        dietary_restriction: "",
        dietary_other: "",
        decision_making: "",
        industry: "",
        business_need: "",
        organization_type: "",
        partnership: "",
        condition_1: false,
        industry_other: "",

        business_other: "",

        patnership_other: "",
      },
      validation_message: {
        salutation: "",
        first_name: "",
        last_name: "",
        organization: "",
        job_title: "",
        email: "",
        phone: "",
        dietary_restriction: "",
        dietary_other: "",
        decision_making: "",
        industry: "",
        business_need: "",
        organization_type: "",
        partnership: "",
        condition_1: "",
      },
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },

    onSubmit() {
      this.loading = true;

      this.resetValidation();

      if (!this.participant.condition_1) {
        this.participant.condition_1 = null;
      }

      Csrf.getCookie().then((res) => {
        Api.post("participant", this.participant).then((res) => {
          // console.log("res", res);
          // return;
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            this.loading = false;

            return;
          }
          if (res.data.special_participant) {
            this.success_alert = false;

            this.success_alert = true;
            this.loading = false;

            this.resetValidation();
            this.successModal();
            return;
          }

          if (!res.data.error) {
            this.loading = false;
            window.location.href = res.data.redirect_link;
            // this.resetValidation();
            // this.successModal();
          }
        });
      });
    },

    resetValidation() {
      this.validation_message = {
        salutation: "",
        first_name: "",
        last_name: "",
        organization: "",
        job_title: "",
        email: "",
        phone: "",
        dietary_restriction: "",
        decision_making: "",
        industry: "",
        business_need: "",
        organization_type: "",
        partnership: "",
        condition_1: "",
        industry_other: "",

        business_other: "",

        patnership_other: "",
      };
    },
    successModal() {
      this.participant = {
        salutation: "",
        first_name: "",
        last_name: "",
        organization: "",
        job_title: "",
        email: "",
        phone: "",
        dietary_restriction: "",
        decision_making: "",
        industry: "",
        business_need: "",
        organization_type: "",
        partnership: "",
        dietary_other: "",
        condition_1: false,
        industry_other: "",
        business_other: "",
        patnership_other: "",
      };
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  .Sansrace-Regular {
    font-family: "Sansrace-Regular";
  }
  .reg {
    .register-text {
      background-image: linear-gradient(
        135deg,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      color: #fff;
    }
    .reg-form {
      font-family: "Nexa-Bold";
      p {
        font-family: "Nexa-Bold";
      }
      .register-btn {
        background-image: linear-gradient(
          360deg,
          rgb(237, 172, 117),
          rgb(218, 70, 103)
        );
        padding: 8px 70px;
        // border-radius: 32px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
